import { render, staticRenderFns } from "./P1MedicalOwnerForm.vue?vue&type=template&id=cd2d9b46&scoped=true&"
import script from "./P1MedicalOwnerForm.vue?vue&type=script&lang=js&"
export * from "./P1MedicalOwnerForm.vue?vue&type=script&lang=js&"
import style0 from "./P1MedicalOwnerForm.vue?vue&type=style&index=0&id=cd2d9b46&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cd2d9b46",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VDivider,VFlex,VForm,VLayout,VSpacer,VSwitch})
