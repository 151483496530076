<template>
  <v-form ref="form" lazy-validation>
    <v-switch
      v-model="p1Data.is_owner"
      class="mx-4 zg-text-control mt-0 pt-0"
      color="expressive_green"
      label="Jestem właścicielem podmiotu leczniczego lub praktyki"
      :ripple="false"
      inset
    ></v-switch>
    <v-layout column v-if="p1Data.is_owner">
      <v-layout align-center class="mx-4 mb-4">
        <DefaultSubtitle color="primary"
          >Dane uwierzytelniające do Systemu P1</DefaultSubtitle
        >
        <InfoButton
          text="<center><b>Uwaga!</b></center> Poniższe dane wypełnia  właściciel podmiotu leczniczego lub właściciel praktyki. Nie wypełniają pracownicy podmiotu leczniczego."
        ></InfoButton>
      </v-layout>
      <v-layout :class="$vuetify.breakpoint.xsOnly && 'wrap'" align-center>
        <v-flex :class="['xs12 sm6', $vuetify.breakpoint.xsOnly && 'mt-2']">
          <LabelTextField
            :value.sync="p1Data.rpwdl"
            label="Numer RPWDL"
            :rules="requiredRPWDL"
            required
          >
            <InfoButton
              slot="header-items"
              text="Numer możesz sprawdzić w wyszukiwarce RPWDL <a href='https://rpwdl.ezdrowie.gov.pl' target='blank'>https://rpwdl.ezdrowie.gov.pl</a> lub na swoim koncie RPWDL, we wniosku. "
            ></InfoButton>
          </LabelTextField>
        </v-flex>
        <v-flex class="xs12 sm6">
          <DefaultSelect
            :value.sync="p1Data.organization_cell"
            label="Typ komórki organizacyjnej"
            placeholder="Wybierz typ komórki organizacyjnej..."
            :items="items"
            clearable
            required
          >
            <InfoButton
              slot="label-icon"
              text="Wybierz typ komórki organizacyjnej, z której będą wysyłane dane do P1 np. podmiot leczniczy."
            ></InfoButton>
          </DefaultSelect>
        </v-flex>
      </v-layout>
      <v-layout :class="'wrap'" align-center>
        <v-flex :class="['xs12 sm6']">
          <LabelTextField
            :value.sync="p1Data.p1_id"
            :rules="requiredP1"
            label="Identyfikator nadany przez P1"
            placeholder="Wpisz identyfikator nadany przez P1..."
            required
            validateOnBlur
          >
            <InfoButton
              slot="header-items"
              text="Wpisz numer ID nadany podczas generowania certyfikatów. Numer otrzymasz w wiadomości e-mail od RPWDL lub po zalogowaniu na Twoim koncie RPWDL."
            ></InfoButton>
          </LabelTextField>
        </v-flex>
        <v-flex v-if="visibleDistChamNum" :class="['xs12 sm6']">
          <LabelTextField
            :value.sync="p1Data.dist_chamb_num"
            :rules="requiredInteger"
            label="Numer izby okręgowej"
            placeholder="Wpisz numer izby okręgowej..."
            required
          >
          </LabelTextField>
        </v-flex>
      </v-layout>
      <v-layout
        :class="$vuetify.breakpoint.smAndDown && 'wrap'"
        align-center
      >
        <v-flex :class="['sm12 md6', $vuetify.breakpoint.smAndDown && 'mt-2']">
          <v-layout column>
            <v-layout>
              <v-flex shrink class="mb-4 mx-4">
                <UploadButton :value.sync="p1Data.tls_cert"></UploadButton>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
            <PasswordField
              :value.sync="p1Data.tls_password"
              label="Hasło do certyfikatu TLS"
              placeholder="Wpisz hasło do certyfikatu TLS"
              required
              :rules="textRules"
              :icon="false"
            >
              <InfoButton
                slot="header-items"
                text="Wpisz hasło jakiego użyłeś podczas generowania certyfikatów."
              ></InfoButton>
            </PasswordField>
          </v-layout>
        </v-flex>
        <v-flex :class="['sm12 md6', $vuetify.breakpoint.smAndDown && 'mt-2']">
          <v-layout column>
            <v-layout>
              <v-flex shrink class="mb-4 mx-4">
                <UploadButton
                  :value.sync="p1Data.wss_cert"
                  type="certyfikat WSS"
                ></UploadButton>
              </v-flex>
              <v-spacer></v-spacer>
            </v-layout>
            <PasswordField
              :value.sync="p1Data.wss_password"
              label="Hasło do certyfikatu WSS"
              placeholder="Wpisz hasło do certyfikatu WSS"
              required
              :rules="textRules"
              :icon="false"
            >
              <InfoButton
                slot="header-items"
                text="Wpisz hasło jakiego użyłeś podczas generowania certyfikatów."
              ></InfoButton>
            </PasswordField>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-divider v-if="visibleRPWDLBookUpload" class="mb-6 mx-4"></v-divider>
      <v-layout v-if="visibleRPWDLBookUpload" :class="'wrap'" align-center>
        <v-flex :class="['xs12 sm6']">
          <v-layout column>
            <v-layout align-center class="mx-4 mb-4">
              <DefaultLabel
                >Księga rejestrowa RPWDL</DefaultLabel
              >
              <InfoButton
                text='Aby pobrać księgę rejestrową:<br>
1. Wejdź na <a href="https://rpwdl.ezdrowie.gov.pl/">https://rpwdl.ezdrowie.gov.pl/</a> .<br>
2. Kliknij "Wyszukiwarki" po lewej stronie menu i wybierz typ komórki organizacyjnej (np. jeśli masz praktykę fizjoterapeutyczną, to wybierz "Wyszukiwarka praktyk zawodowych terapeutów").<br>
3. W wyszukiwarce wpisz swój numer RPWDL do pola "Numer księgi rejestrowej" i naciśnij "Szukaj".<br>
4. Na liście wyników wyszukiwania, pojawi się jeden rekord, kliknij "Pobierz" po prawej stronie.<br>
5. Zapisany plik księgi rejestrowej w formacie "zip" na Twoim komputerze dodaj na swoim koncie na zarejestrowani.pl, w zakładce Integracje/P1 klikając "Kliknij by dodać księgę rejestrową".'
              ></InfoButton>
            </v-layout>
            <v-flex shrink class="mb-4 mx-4">
              <UploadButton
                type="księgę rejestrową"
                accept=".zip"
                :value.sync="p1Data.book"
              ></UploadButton>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-layout>
  </v-form>
</template>

<script>
import Validators from "@/mixins/Validators";

export default {
  mixins: [Validators],
  props: {
    p1Data: {},
  },
  data() {
    return {
      items: [
        { value: "F", text: "Praktyka fizjoterapeutyczna" },
        { value: "L", text: "Praktyka lekarska" },
        { value: "O", text: "Podmiot leczniczy" },
        { value: "P", text: "Praktyka pielęgniarska" },
      ],
    };
  },
  computed: {
    visibleDistChamNum() {
      return this.p1Data && ["L", "P"].includes(this.p1Data.organization_cell);
    },
    visibleRPWDLBookUpload() {
      return (
        this.p1Data && ["F", "L", "P"].includes(this.p1Data.organization_cell)
      );
    },
  },
  components: {
    UploadButton: () => import("@/components/buttons/p1/UploadButton"),
    LabelTextField: () => import("@/components/LabelTextField"),
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
    InfoButton: () => import("@/components/buttons/InfoButton"),
    PasswordField: () => import("@/components/auth/PasswordField"),
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    DefaultLabel: () => import("@/components/text/DefaultLabel"),
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.zg-text-control::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>